import classNames from 'classnames'
import { Field } from 'formik'
import { FC, ReactNode, PropsWithChildren } from 'react'
import Style from './input.module.scss'
interface InputProps {
  label?: string
  type?: string
  name: string
  placeholder?: string
  startIcon?: ReactNode
  endIcon?: ReactNode
  autocomplete?: boolean
  error?: boolean
  overflow?: 'hidden' | 'visible' | 'auto'
  as?: 'input' | 'select' | 'textarea'
  onFocus?: () => void
  onClick?: () => void
  readOnly?: boolean
  value?: any
}

export const InputContainer: FC<PropsWithChildren<Partial<InputProps>>> = props => {
  return (
    <div
      style={{ overflow: props.overflow }}
      className={classNames({ [Style.inputField]: true, [Style.error]: props.error })}
    >
      {props.children}
    </div>
  )
}

function Input({
  autocomplete = true,
  name,
  placeholder,
  type,
  startIcon,
  endIcon,
  error = false,
  as = 'input',
  overflow = 'hidden',
  onFocus,
  onClick,
  readOnly = false,
  value,
}: InputProps) {
  return (
    <InputContainer error={error} overflow={overflow}>
      {startIcon && <div>{startIcon}</div>}
      <Field
        readOnly={readOnly}
        onClick={onClick}
        onFocus={onFocus}
        type={type}
        id={name}
        name={name}
        autoComplete={autocomplete ? 'on' : 'new-password'}
        placeholder={placeholder}
        as={as}
        value={value}
      />
      {endIcon && <div>{endIcon}</div>}
    </InputContainer>
  )
}

export default Input
